define(['coreUtils/core/propsSelectorsUtils'], function (propsSelectorsUtils) {
    'use strict';
    const createComponentSantaType = propsSelectorsUtils.createComponentSantaType;
    /**
     * @memberOf coreUtils
     * @deprecated
     */
    const staticValue = function (propType, value) {
        return createComponentSantaType(propType, function () {
            return value;
        });
    };

    return staticValue;
});

